body {
  font-family: "Inter", sans-serif;
  background-color: #A0522D;
  color: white;
}

.bg-linear-red {
  background: linear-gradient(#A0522D, #7B3F00);
}

.card {
  perspective: 1000px;
}

.card-face {
  position: absolute;
  backface-visibility: hidden;
  transition: transform 1s;
}

.card .back {
  transform: rotateY(180deg);
}

.card.flipped .front {
  transform: rotateY(180deg);
}

.card.flipped .back {
  transform: rotateY(0deg);
}
